import React from 'react';
import Helmet from 'react-helmet';
import Offerings from '../Offerings';
// import Testimonials from '../Testimonials';
import PropTypes from 'prop-types';

const HomePageTemplate = ({
  title,
  heading,
  description,
  home_text,
  logo,
  offerings,
  meta_title,
  meta_description,
  testimonials,
}) => (
  <div>
    <Helmet>
      <title>{meta_title}</title>
      <meta name='description' content={meta_description} />
    </Helmet>
    <section className='hero is-primary is-bold is-medium'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-one-fifth is-1'>
              <img alt='' src={logo} />
            </div>
            <div className='column is-10'>
              <div className='section'>
                <h1 className='title'>{title}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='section section--gradient'>
      <div className='container'>
        <div className='section'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='content'>
                <div>
                  <h3 className='has-text-weight-semibold is-size-2'>
                    {heading}
                  </h3>
                  <p className='has-text-justified'>{description}</p>
                  <p className='has-text-justified'>{home_text}</p>
                </div>
                <Offerings gridItems={offerings.blurbs} />
                {/* <h2 className='has-text-weight-semibold is-size-2'>
                  Drugi o nama
                </h2> */}
                {/* <Testimonials testimonials={testimonials} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  home_text: PropTypes.string,
  logo: PropTypes.string,
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  testimonials: PropTypes.array,
};

export default HomePageTemplate;
